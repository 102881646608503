import { useEffect, useRef, useState } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';
import axios, { AxiosError } from 'axios';

import { IPagination } from '../models/pagination.model';
import { TStatus } from '../models/status.model';
import { IFilter, IFilters, ISelectedFilter } from '../models/filter.model';
import { IApiKeywords } from '../models/api-keywords.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { getNodes } from '../utils/get-nodes';
import { isBrowser } from '../utils/is-browser';
import { getUserTokenData } from '../utils/get-user-token-data';
import { forceUserLogout } from '../utils/force-user-logout';

const DEFAULT_PER_PAGE = 9;
const DEFAULT_INITIAL_PAGE = 1;

export interface IUseListConfig {
    endpoint: string | null;
    additionalParams?: Record<string, string>;
    perPage?: number;
    sort?: string;
    excludePaginationParams?: boolean;
    token?: string;
    paramsWhiteList?: string[];
    ignoredParams?: string[];
    apiKeywords?: Partial<IApiKeywords>;
    initialParams?: Record<string, string>;
    fetchInitialParams?: boolean;
    // 🦞
    ignoreIsInitialLoadingDoneOnChange?: boolean;
}

export interface IAlterpressPaginatedResponse<Items> {
    items: Items[];
    pagination?: IPagination | null;
    filters?: IFilters | null;
    sort?: IFilter | null;
}

type TStaticQueryResult = {
    allApiKeywords: IQueryAllResult<IApiKeywords>;
};

const defaultKeywords = {
    locale: 'en',
    perPage: 'per-page',
    search: 'search',
    page: 'page',
    sort: 'sort,',
    categories: '',
};

export const useList = <Items>(config: IUseListConfig) => {
    const {
        endpoint,
        additionalParams = {},
        perPage = DEFAULT_PER_PAGE,
        excludePaginationParams = false,
        token = '',
        paramsWhiteList = [],
        ignoredParams = [],
        apiKeywords: givenApiKeywords,
        ignoreIsInitialLoadingDoneOnChange,
        initialParams,
        fetchInitialParams = false,
    } = config;
    const { locale } = usePageContext();
    const { allApiKeywords } = useStaticQuery<TStaticQueryResult>(query);
    const apiKeywords = getNodes(allApiKeywords).find((keywords) => keywords.locale === locale);
    const search = getUrlSearchWithoutIgnored(ignoredParams);
    const additionalParamsString = JSON.stringify(additionalParams);
    const { current: keywords } = useRef<IApiKeywords>({
        locale: givenApiKeywords?.locale || apiKeywords?.locale || defaultKeywords.locale,
        perPage: givenApiKeywords?.perPage || apiKeywords?.perPage || defaultKeywords.perPage,
        search: givenApiKeywords?.search || apiKeywords?.search || defaultKeywords.search,
        sort: givenApiKeywords?.sort || apiKeywords?.sort || defaultKeywords.sort,
        page: givenApiKeywords?.page || apiKeywords?.page || defaultKeywords.page,
        categories:
            givenApiKeywords?.categories || apiKeywords?.categories || defaultKeywords.categories,
    });
    const isUserTokenInLocalStorage = !!getUserTokenData();

    const initialSearchValueRef = useRef(getInitialSearchValue(search, keywords.search));
    const paramsRef = useRef<Record<string, string | string[]> | null>(null);
    const prevSearchRef = useRef<string | undefined>();
    const additionalParamsStringRef = useRef<string>(JSON.stringify(additionalParamsString));
    const prevRefetchSwitchRef = useRef(false);

    const [isInitialLoadingDone, setIsInitialLoadingDone] = useState(false);
    const [data, setData] = useState<IAlterpressPaginatedResponse<Items>>({ items: [] });
    const [paginationPaths, setPaginationPaths] = useState<string[]>([]);
    const [status, setStatus] = useState<TStatus>('loading');
    const [selectedFilters, setSelectedFilters] = useState<ISelectedFilter[]>([]);
    const [values, setValues] = useState<Record<string, string | string[]>>({});
    const [refetchSwitch, setRefetchSwitch] = useState(false);
    const [emptyCanBeDefined, setEmptyCanBeDefined] = useState(false);
    const [localInitialParams, setLocalInitialParams] = useState(initialParams);
    const [isInitialParamsFetchDone, setIsInitialParamsFetchDone] = useState(
        !fetchInitialParams || search
    );
    const [initialParamsRedirectDone, setInitialParamsRedirectDone] = useState(false);

    const handleSearch = (value: string) => {
        if (typeof window === 'undefined') return;

        const searchParams = new URLSearchParams(search);
        const searchValue = searchParams.get(keywords.search) || '';

        if (value === searchValue) return;

        searchParams.delete(keywords.page);

        if (value) {
            searchParams.set(keywords.search, value);
        } else {
            searchParams.delete(keywords.search);
        }

        const newSearchParamsString = searchParams.toString();
        const pathToNavigate = `${window.location.pathname}${
            newSearchParamsString ? `?${newSearchParamsString}` : ''
        }`;

        setEmptyCanBeDefined(false);
        navigate(pathToNavigate);
    };

    const handleChange = (params: Record<string, string | string[]>) => {
        if (!isInitialLoadingDone && !ignoreIsInitialLoadingDoneOnChange) return;
        paramsRef.current = params;

        const newSelectedFilters = getSelectedFilters(data.filters, params);

        setSelectedFilters(newSelectedFilters);

        const prevSearchParams = new URLSearchParams(search);

        const whiteListedParamsEntries = [];
        for (const [key, value] of prevSearchParams.entries()) {
            if (paramsWhiteList.includes(key)) {
                whiteListedParamsEntries.push([key, value]);
            }
        }

        if (!whiteListedParamsEntries.find((item) => item[0] === 'page')) {
            prevSearchParams.delete(keywords.page);
        }

        const newSearchParams = new URLSearchParams();

        let paramsEntries = Object.entries(params);

        ignoredParams.forEach((param) => {
            paramsEntries = paramsEntries.filter((entry) => !entry[0].startsWith(param));
        });

        const paramsEntriesWithValues = paramsEntries.filter((entry) => {
            const value = entry[1];
            if (!Array.isArray(value)) return !!value;
            return value.length > 0;
        });

        for (const [paramName, paramValue] of paramsEntriesWithValues) {
            const filter =
                data.filters &&
                Object.values(data.filters).find((filter) => filter.paramName === paramName);
            let searchParamValue = paramValue;
            if (Array.isArray(searchParamValue)) {
                if (filter && filter.type === 'radio-range' && Array.isArray(searchParamValue)) {
                    searchParamValue = searchParamValue.map((value) => value.replaceAll(',', '.'));
                }
                searchParamValue = searchParamValue.join(',');
            }
            newSearchParams.set(paramName, searchParamValue);
        }

        const searchValue = prevSearchParams.get(keywords.search);
        if (searchValue) {
            newSearchParams.set(keywords.search, searchValue);
        }

        if (whiteListedParamsEntries.length) {
            whiteListedParamsEntries.forEach(([key, value]) => {
                newSearchParams.set(key, value);
            });
        }

        const newSearchParamsString = getSearchParamsString(newSearchParams);
        const prevSearchParamsString = getSearchParamsString(prevSearchParams);

        if (getAreSearchParamsStringsEqual(prevSearchParamsString, newSearchParamsString)) return;

        const pathToNavigate = `${window.location.pathname}${newSearchParamsString}`;

        setEmptyCanBeDefined(false);
        navigate(pathToNavigate, { state: { preventScroll: true } });
    };

    useEffect(() => {
        if (!fetchInitialParams || isInitialParamsFetchDone) return;
        setStatus('loading');
        const abortController = new AbortController();
        axios
            .get<IAlterpressPaginatedResponse<Items>>(`${process.env.API_URL}${endpoint}`, {
                params: {
                    ...(excludePaginationParams
                        ? {}
                        : { [keywords.perPage]: perPage, [keywords.page]: DEFAULT_INITIAL_PAGE }),
                },
                headers: { 'Accept-Language': locale, Authorization: `Bearer ${token}` },
                signal: abortController.signal,
            })
            .then((response) => {
                setIsInitialParamsFetchDone(true);
                setLocalInitialParams(getParamsFromFilters(response.data.filters));
            })
            .catch((error: AxiosError) => {
                // ERR_CANCELED means that this request was aborted by the AbortController
                // and next response is on its way, so we don't want to show error
                if (error.code === 'ERR_CANCELED') return;
                setStatus('error');
                // if there is a user token in localStorage and the error status is 401,
                // it means that the user should be logged out
                if (isUserTokenInLocalStorage && error.response?.status === 401) {
                    forceUserLogout();
                }
            });

        return () => {
            abortController.abort();
        };
    }, [
        endpoint,
        excludePaginationParams,
        fetchInitialParams,
        isInitialParamsFetchDone,
        isUserTokenInLocalStorage,
        keywords.page,
        keywords.perPage,
        locale,
        perPage,
        token,
    ]);

    useEffect(() => {
        if (endpoint === null) return;
        if (initialParamsRedirectDone || !isInitialParamsFetchDone) return;
        if (localInitialParams) {
            const params = new URLSearchParams(search);
            Object.entries(localInitialParams).forEach(([key, value]) => {
                if (params.has(key)) return;
                params.append(key, value);
            });
            const newSearchParamsString = getSearchParamsString(params);
            const pathToNavigate = `${window.location.pathname}${newSearchParamsString}`;
            navigate(pathToNavigate, { replace: true });
        }
        setInitialParamsRedirectDone(true);
    }, [localInitialParams, initialParamsRedirectDone, search, endpoint, isInitialParamsFetchDone]);

    useEffect(() => {
        if (endpoint === null) return;
        if (!initialParamsRedirectDone) return;
        setEmptyCanBeDefined(true);
        if (
            getAreSearchParamsStringsEqual(prevSearchRef.current, search) &&
            additionalParamsStringRef.current === additionalParamsString &&
            prevRefetchSwitchRef.current === refetchSwitch
        )
            return;

        prevSearchRef.current = search;
        additionalParamsStringRef.current = additionalParamsString;
        prevRefetchSwitchRef.current = refetchSwitch;

        setStatus('loading');
        const abortController = new AbortController();
        const currentAdditionalParams = JSON.parse(additionalParamsString);
        const searchParamsObject = getSearchParamsObject(
            search,
            currentAdditionalParams,
            paramsWhiteList
        );
        axios
            .get<IAlterpressPaginatedResponse<Items>>(`${process.env.API_URL}${endpoint}`, {
                params: {
                    ...(excludePaginationParams
                        ? {}
                        : { [keywords.perPage]: perPage, [keywords.page]: DEFAULT_INITIAL_PAGE }),
                    ...searchParamsObject,
                },
                headers: { 'Accept-Language': locale, Authorization: `Bearer ${token}` },
                signal: abortController.signal,
            })
            .then((response) => {
                setStatus('success');
                const usableFilters = getUsableFilters(response.data.filters);
                const dataWithUsableFilters = { ...response.data, filters: usableFilters };
                setData(dataWithUsableFilters);
                setPaginationPaths(getPaginationPaths(response.data.pagination, keywords));
                const newValues = getValues(search, keywords, usableFilters, response.data.sort);
                setValues(newValues);
                const valuesForSelectedFilters = paramsRef.current || newValues;
                setSelectedFilters(getSelectedFilters(usableFilters, valuesForSelectedFilters));
                setIsInitialLoadingDone(true);
            })
            .catch((error: AxiosError) => {
                // ERR_CANCELED means that this request was aborted by the AbortController
                // and next response is on its way, so we don't want to show error
                if (error.code === 'ERR_CANCELED') return;
                setStatus('error');
                // if there is a user token in localStorage and the error status is 401,
                // it means that the user should be logged out
                if (isUserTokenInLocalStorage && error.response?.status === 401) {
                    forceUserLogout();
                }
            });

        return () => {
            abortController.abort();
        };
    }, [
        additionalParamsString,
        locale,
        perPage,
        search,
        endpoint,
        keywords,
        data.filters,
        data.sort,
        refetchSwitch,
        initialParamsRedirectDone,
    ]);

    return {
        items: data.items,
        filters: data.filters,
        sort: data.sort,
        pagination: data.pagination,
        data: data.items ? undefined : data,
        rawData: data,
        status,
        isInitialLoading: !isInitialLoadingDone,
        paginationPaths,
        selectedFilters,
        handleSearch,
        handleChange,
        values,
        initialSearchValue: initialSearchValueRef.current,
        isEmpty:
            emptyCanBeDefined &&
            !search &&
            status === 'success' &&
            data.items &&
            data.items.length === 0,
        refetchList: () => setRefetchSwitch((prev) => !prev),
    };
};

function getAreSearchParamsStringsEqual(prev: string | undefined, next: string) {
    if (prev === undefined || prev.length !== next.length) return false;
    const prevArr = prev.replace('?', '').split('&');
    const nextArr = next.replace('?', '').split('&');
    return prevArr.every((param) => nextArr.includes(param));
}

function getUrlSearchWithoutIgnored(ignoredParams: IUseListConfig['ignoredParams']) {
    const search = isBrowser() ? window.location.search : '';
    if (!ignoredParams) return search;
    const params = new URLSearchParams(search);
    ignoredParams.forEach((param) => {
        params.delete(param);
    });
    return params.toString() ? `?${params.toString()}` : '';
}

function getUsableFilters(
    filters: IAlterpressPaginatedResponse<unknown>['filters']
): IAlterpressPaginatedResponse<unknown>['filters'] {
    if (!filters) return filters;
    const usableFilters: IFilters = {};
    Object.entries(filters).forEach(([key, filter]) => {
        if (!filter.usable) return;
        usableFilters[key] = filter;
    });
    return usableFilters;
}

function getSearchParamsString(searchParams: URLSearchParams) {
    let searchParamsString = searchParams.toString().replace(/%2C/g, ',');
    if (searchParamsString) {
        searchParamsString = `?${searchParamsString}`;
    }
    return searchParamsString;
}

function getSelectedFilters(
    filters?: IFilters | null,
    formValues?: Record<string, string | string[]> | null
) {
    if (!filters || !formValues) return [];
    return Object.entries(formValues)
        .map(([key, value]) => {
            const filter = Object.values(filters).find((filter) => filter.paramName === key);
            if (!filter) return [];
            const valueArr = Array.isArray(value) ? value : [value];
            return valueArr
                .map((valueItem, index) => {
                    let option = filter.options?.find(
                        (option) => option.value.toString() === valueItem.toString()
                    );
                    let rangeEdge;
                    if (filter.type === 'radio-range' && !option) {
                        const optionLabel = `${['min', 'max'][index]} ${valueItem}`;
                        rangeEdge = ['min', 'max'][index];
                        option = {
                            value: valueItem,
                            label: optionLabel,
                            applied: true,
                        };
                    }
                    if (filter.type === 'date') {
                        option = {
                            value: valueItem,
                            label: filter.label,
                            applied: true,
                        };
                    }
                    return {
                        paramName: key,
                        paramLabel: filter.label,
                        filterType: filter.type,
                        rangeEdge,
                        option,
                    };
                })
                .filter((selectedFilter) => !!selectedFilter.option && selectedFilter.option.value);
        })
        .flat() as ISelectedFilter[];
}

function getValues(
    search: string,
    apiKeywords: IApiKeywords,
    filters: IFilters | null | undefined,
    sortFilter: IFilter | null | undefined
) {
    const keysToOmit = [apiKeywords.search, apiKeywords.page];
    const searchParams = new URLSearchParams(search);
    const values: Record<string, string | string[]> = {};

    for (const [paramKey, paramValue] of searchParams) {
        if (keysToOmit.includes(paramKey)) continue;
        let filter: IFilter | undefined;
        if (sortFilter && paramKey === sortFilter.paramName) {
            filter = sortFilter;
        } else if (filters) {
            filter = Object.values(filters).find((filter) => filter.paramName === paramKey);
        }
        if (!filter) continue;
        if (filter.type === 'checkbox') {
            values[paramKey] = paramValue.split(',');
        }
        if (filter.type === 'radio' || filter.type === 'select' || filter.type === 'date') {
            values[paramKey] = paramValue;
        }
        if (filter.type === 'radio-range') {
            const valueArr = paramValue.split(',');
            values[paramKey] = valueArr.length > 1 ? valueArr : paramValue;
        }
    }

    return values;
}

function getInitialSearchValue(search: string, searchKey: string) {
    if (typeof window === 'undefined') return '';
    const searchParams = new URLSearchParams(search);
    return searchParams.get(searchKey) || '';
}

function getSearchParamsObject(
    search: string,
    additionalParams: IUseListConfig['additionalParams'],
    paramsWhiteList: IUseListConfig['paramsWhiteList'] = []
) {
    const searchParams = new URLSearchParams(search);
    const searchParamsObject: Record<string, string> = {};

    for (const [key, value] of searchParams) {
        if (!paramsWhiteList.includes(key)) {
            searchParamsObject[key] = value;
        }
    }

    if (additionalParams) {
        for (const [key, value] of Object.entries(additionalParams)) {
            if (searchParamsObject[key]) {
                searchParamsObject[key] = `${searchParamsObject[key]},${value}`;
            } else {
                searchParamsObject[key] = value;
            }
        }
    }

    return searchParamsObject;
}

function getPaginationPaths(pagination: IPagination | null | undefined, apiKeywords: IApiKeywords) {
    if (!pagination) return [];
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(apiKeywords.page);

    const paginationPaths = [];
    const search = searchParams.toString();
    const pathname = window.location.pathname;

    for (let i = 0; i < pagination.pageCount; i++) {
        const page = i + 1;
        const pageParams = page === 1 ? '' : `${apiKeywords.page}=${page}`;
        let params = `?${search}&${pageParams}`;
        if (!search) {
            params = pageParams ? `?${pageParams}` : '';
        }
        if (!pageParams) {
            params = search ? `?${search}` : '';
        }
        const url = `${pathname}${params}`;
        paginationPaths.push(url);
    }

    return paginationPaths;
}

function getParamsFromFilters(filters: IFilters | null | undefined) {
    if (!filters) return {};
    return Object.values(filters).reduce<Record<string, string>>((acc, filter) => {
        const value =
            (filter.options &&
                filter.options
                    .filter((option) => option.applied)
                    .map((option) => option.value)
                    .join(',')) ||
            '';
        if (value) {
            acc[filter.paramName] = value;
        }
        return acc;
    }, {});
}

const query = graphql`
    query {
        allApiKeywords {
            edges {
                node {
                    locale
                    search
                    sort
                    page
                    perPage
                }
            }
        }
    }
`;
